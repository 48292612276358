import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";

/* 
  -fehler bei textupload in backend, was angezeigt???
  - 'data feld muss ausgefüllt sein, bisher ohne benarchitgn...
  - textarea text richtig abstimmen....
*/
export default class OpenStreetMap extends Standard {
  constructor() {
    super("#OpenStreetMap");
    this.id = "OpenStreetMap";
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.container.addEventListener("click", (e) => {
      // window.location.href = "https://goo.gl/maps/kVYNnLHBj6BS4oaR8";
    });
    this.init();
  }

  async init() {
    try {
      const x = 48.8801;
      const y = 12.6271;
      var mymap = L.map("OpenStreetMap", { scrollWheelZoom: false }).setView(
        [x, y],
        14
      );
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mymap);
      L.marker([x, y]).addTo(mymap);
    } catch (error) {
      console.log("error OpenStreetMap");
      console.log(error);
    }
  }
}
