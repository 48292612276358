import Back2Top from './_Back2Top.js';
import CookieAbfrage from './_CookieAbfrage.js';
import './_Links';
import Links from './_Links';
import Navbar from './_Navbar.js';
import './_afarkas_lazyload';
const footer_year = document.querySelector('#footer_year');
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}

new Back2Top();
new CookieAbfrage();
new Navbar();
new Links();
