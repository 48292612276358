/*
Example:
<div class="test link_to" link_to="https://goo.gl/maps/fF7gYyErjSEZpUV57">
<div class="test link_to_blanket" link_to="https://goo.gl/maps/fF7gYyErjSEZpUV57">
*/
import Standard from "../../js/Libraries/Standard";

export default class Links extends Standard {
  constructor() {
    super();
    this.link_to = document.querySelectorAll(".link_to");
    this.link_to_blanket = document.querySelectorAll(".link_to_blanket");
    this.init();
  }

  init() {
    this.events();
  }

  events() {
    this.link_to.forEach((el) => {
      el.addEventListener("click", () => {
        let link = el.getAttribute("link_to");
        if (link !== null) {
          window.location.href = link;
        }
      });
    });
    this.link_to_blanket.forEach((el) => {
      el.addEventListener("click", () => {
        let link = el.getAttribute("link_to");
        if (link !== null) {
          window.open(link, "_blank");
        }
      });
    });
  }
}
