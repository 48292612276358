import Standard from "../../js/Libraries/Standard";

import Handlers from "../../js/Libraries/Handlers";

export default class Video extends Standard {
  constructor() {
    super("#video");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.video = super.set("#videoTag");
    this.play = super.set("#play");
    this.stop = super.set("#stop");
    this.progress = super.set("#progress");
    this.timestamp = super.set("#timestamp");
    this.init();
  }

  async init() {
    // this.events();
  }

  toggleVideoStatus() {
    if (this.video.paused) {
      this.video.play();
    } else {
      this.video.pause();
    }
  }
  updatePlayIcon() {
    if (this.video.paused) {
      this.play.innerHTML = '<i class="fa fa-play fa-2x"></i>';
    } else {
      this.play.innerHTML = '<i class="fa fa-pause fa-2x"></i>';
    }
  }
  updateProgress() {
    this.progress.value = (this.video.currentTime / this.video.duration) * 100;

    // Get minutes
    let mins = Math.floor(this.video.currentTime / 60);
    if (mins < 10) {
      mins = "0" + String(mins);
    }

    // Get seconds
    let secs = Math.floor(this.video.currentTime % 60);
    if (secs < 10) {
      secs = "0" + String(secs);
    }

    this.timestamp.innerHTML = `${mins}:${secs}`;
  }
  events() {
    // this.video.addEventListener("click", () => this.toggleVideoStatus());
    // this.video.addEventListener("pause", () => this.updatePlayIcon());
    // this.video.addEventListener("play", () => this.updatePlayIcon());
    // this.video.addEventListener("timeupdate", () => this.updateProgress());
    // this.play.addEventListener("click", () => this.toggleVideoStatus());
    // this.stop.addEventListener("click", () => {
    //   this.video.currentTime = 0;
    //   this.video.pause();
    // });
    // this.progress.addEventListener("change", () => {
    //   this.video.currentTime =
    //     (+this.progress.value * this.video.duration) / 100;
    // });
    // document.addEventListener("scoll", () => {
    //   if (this.video.paused) {
    //     this.video.play();
    //   }
    // });
  }
}
