import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";

/* 
  -fehler bei textupload in backend, was angezeigt???
  - 'data feld muss ausgefüllt sein, bisher ohne benarchitgn...
  - textarea text richtig abstimmen....
*/
export default class TextAnimation extends Standard {
  constructor() {
    super("#Textanimation");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.word_1 = "every load";
    this.word_2 = " every road";
    this.word_3 = "every load every road";
    this.time_1 = 350;
    this.time_2 = 1000;
    this.init();
  }

  async init() {
    await this.oneAnimation();
  }
  async oneAnimation() {
    await this.hl.wait(this.time_1);
    var print = "";
    for (var i = 0; i < this.word_1.length; i++) {
      print = this.word_1[i];
      this.container.innerHTML = this.container.innerHTML + print;
      await this.hl.wait(this.time_1);
    }
    await this.hl.wait(750);
    var print = "";
    for (var i = 0; i < this.word_2.length; i++) {
      print = this.word_2[i];
      this.container.innerHTML = this.container.innerHTML + print;
      await this.hl.wait(this.time_1);
    }

    await this.hl.wait(1000);
    for (var j = this.word_3.length; j >= 0; j--) {
      var printminus = this.word_3.slice(0, j);
      this.container.innerHTML = printminus;
      await this.hl.wait(this.time_1 / 2);
    }

    this.oneAnimation();
  }
}
