import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import jump from "jump.js";
import tippy from "tippy.js";
//.. geht nicht in debug browser ud dev env?
// am ende nicht vergessen AOS.init();

const scroollableFadeUp = document.querySelectorAll(".scroollableFadeUp");

scroollableFadeUp.forEach((each) => {
  each.setAttribute("data-aos", "fade-up");
  each.setAttribute("data-aos-offset", "80");
  each.setAttribute("data-aos-duration", "1000");
  each.setAttribute("data-aos-anchorPlacement", "top-bottom");
});

window.addEventListener("load", () => {
  AOS.init();
});

window.addEventListener("resize", () => {
  AOS.refresh();
});

const allTippies = document.querySelectorAll(".tippy-el");

allTippies.forEach((tipp) => {
  const text = tipp.getAttribute("tippyText");
  tippy(tipp, {
    content: `<div class="tippy-content">${text}</div>
    `,
    allowHTML: true,
  });
});

// linkd jump to
const allJumpTo = document.querySelectorAll(".jump-to-el");

allJumpTo.forEach((el) => {
  const ref = el.getAttribute("hrefJump");
  el.addEventListener("click", (e) => {
    e.preventDefault();
    const goal = document.querySelector(ref);

    if (goal !== null) {
      jump(goal, {
        duration: (distance) => Math.abs(distance) / 2,
      });
    } else {
      window.location.href = el.href;
    }
  });
});

const link_to_blanket = document.querySelectorAll(".link_to_blanket");
link_to_blanket.forEach((el) => {
  el.addEventListener("click", () => {
    let link = el.getAttribute("link_to");
    if (link !== null) {
      window.open(link, "_blank");
    }
  });
});
